import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export const constantRoutes = [
    {
        path: '/',
        redirect: '/home',

    },
    {
        path: '/login',
        component: () => import('@/views/home/loginTrial'),
        hidden: true
    },
    {
        path: '/home',
        component: () => import('@/views/login/loginIndex'),
        hidden: true
    }
]

const createRouter = () => new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const router = createRouter()

export function resetRouter () {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
