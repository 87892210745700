import Vue from 'vue'

import 'normalize.css/normalize.css'
import ElementUI from 'element-ui'
import './styles/element-variables.scss'

import '@/styles/index.scss'

import App from './App.vue'
import router from './router'

import pom from '@/utils/pcOrMobile' // 引入pc与mobile检测文件
// import '@/utils/rem' // 引入移动端rem适配
Vue.use(pom) // 插件增强vue功能

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)

Vue.use(ElementUI)

import VeBmap from 'v-charts/lib/bmap.common'

Vue.component(VeBmap.name, VeBmap)

import 'vant/lib/index.css';
import { DropdownMenu, DropdownItem } from 'vant';

Vue.use(DropdownMenu);
Vue.use(DropdownItem);

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
